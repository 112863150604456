import React from 'react';
import { Toast as IToast } from 'react-hot-toast/headless';

interface ToastProps {
  toast: IToast;
};

const typeColorMap: { [name: string]: string } = {
  success: 'success',
  error: 'danger',
  loading: 'info',
  blank: 'white',
  custom: 'white'
};

const Toast: React.FC<ToastProps> = (props) => {
  const toast = props.toast;

  return <div key={toast.id} {...toast.ariaProps} className={`bg-${typeColorMap[toast.type]} text-${typeColorMap[toast.type]}-text border border-1 border-${typeColorMap[toast.type]}-dark shadow-lg mx-auto w-fit max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block p-4 m-2`}>
    {toast.message as React.ReactNode}
  </div>;
};

export default Toast;
