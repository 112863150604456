import React from 'react';
import { useToaster } from 'react-hot-toast/headless';

import Toast from './Toast';

const ToastNotifications: React.FC = () => {
  const { toasts, handlers: { startPause, endPause } } = useToaster();
  return <div className='fixed right-0 top-10 ' onMouseEnter={startPause} onMouseLeave={endPause}>
    <div className='flex space-x-2 justify-center flex-col-reverse '>
      {toasts.filter((toast) => toast.visible).map((toast) => <Toast key={toast.id} toast={toast} />)}
    </div>
  </div>;
};

export default ToastNotifications;
