import React from 'react';

import { Variant } from '../../types/IThemeColors';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  disabled?: boolean;
  selected?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, className, variant, ...otherProps } = props;
  const btnVariant = variant ?? 'primary';

  return <button className={`${className ?? ''} m-1 px-2 py-1 rounded-lg text-${btnVariant}-text bg-${btnVariant} hover:bg-${btnVariant}-dark shadow-md shadow-hover ${otherProps.disabled === true ? 'pointer-events-none opacity-60' : ''}`} {...otherProps} ref={ref}>{children}</button>;
});

Button.displayName = 'Button';

export default Button;
