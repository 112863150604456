import React, { ReactNode } from 'react';

import useTitle from '../../libs/useTitle';

interface AuthErrorProps {
  errorText: string;
  errorText2: string;
  children?: ReactNode;
}

const AuthError: React.FC<AuthErrorProps> = (props: AuthErrorProps) => {
  useTitle('Error');

  return <>
  <div className='flex h-screen bg-background text-foreground'>
      <div className='flex flex-col mx-auto my-auto'>
      <div className='mx-auto'>
          <svg className={'fill-foreground h-60'}>
            <use href={'/icons.svg#cybro-sitting'} />
          </svg>
        </div>
        <h1 className='text-2xl md:text-4xl pb-4 text-center'>{props.errorText}</h1>
        <h2 className='text-lg'>{props.errorText2}</h2>
        <div className={'md:h-40'}>{props.children}</div>
      </div>
  </div>
  </>;
};

export default AuthError;
